import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import Avatar from 'components/Avatar';
import BaseImage from 'components/BaseImage';
import BasePopup from 'components/BasePopup';
import LMSButton from 'components/LMSButton';

import { Course } from '../../entities/Course';

interface Props {
  course: Course;
}

const CourseCard: React.FC<Props> = ({ course }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleCancel = () => {
    setIsOpen(!isOpen);
  };

  const goToCourseDetail = (id: number, groupId: number) => {
    navigate(`/student/active/${id}?groupId=${groupId}`);
  };

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-badge text-xs md:text-base rounded-full truncate overflow-hidden';

  const progressBarWidth = useMemo(() => {
    if (course.lessonsLeft === 0) return '100%';

    const percentage = Math.floor(
      ((course.lessonsCount - course.lessonsLeft) * 100) / course.lessonsCount
    );
    return `${percentage}%`;
  }, [course]);

  return (
    <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-9 p-4 md:p-8 rounded-3xl theme-student-card">
      <div className="w-full md:w-[300px] lg:w-[364px] h-[210px] lg:h-[266px] shrink-0 bg-white/20 rounded-2xl overflow-hidden">
        {/* <img className="w-full h-full object-cover" src={course.imageUrl}  /> */}
        <BaseImage image={course.imageUrl} alt={course.name} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-wrap space-x-2">
          {course.duration ? <p className={badgeClass}>{course.duration}</p> : ''}
          {course.dateFrom ? <p className={badgeClass}>{course.dateFrom}</p> : ''}
          {course.courseType ? <p className={badgeClass}>{course.courseType}</p> : ''}
          {course.lessonsCount ? <p className={badgeClass}>{course.lessonsCount} занятий</p> : ''}
          <div
            className="flex items-center space-x-2 pr-3 mb-2 theme-badge rounded-full relative cursor-pointer"
            onClick={handleCancel}
          >
            <div className="w-7 md:w-10 h-7 md:h-10 rounded-full">
              <Avatar image={course.manager.photo} alt={course.manager.fullName} />

              {isOpen && (
                <BasePopup isOpen={isOpen} onClose={handleCancel}>
                  <aside className="text-black w-full flex flex-col gap-y-1">
                    <p className="text-black text-lg mb-2">{course.manager.fullName}</p>
                    <a href={'tel:' + course.manager.phoneNumber} className="text-grey text-base">
                      {course.manager.phoneNumber}
                    </a>
                    <a href={'mailto:' + course.manager.email} className="text-grey">
                      {course.manager.email}
                    </a>
                  </aside>
                </BasePopup>
              )}
            </div>
            <p className="max-w-[130px] text-xs md:text-base truncate">{course.manager.fullName}</p>
          </div>
          {course.groupName && <p className={badgeClass}>{course.groupName}</p>}
          {course.groupNumber > 1 && <p className={badgeClass}>{course.groupNumber} групп</p>}
        </div>
        <p className=" flex-1 mt-5 mb-5 lg:mt-10 text-2xl">{course.name}</p>
        <div className="flex items-center justify-between flex-wrap md:space-x-2">
          {course.lessonsCount > 0 ? (
            <div className="flex-1 flex items-center min-w-[200px] max-w-full md:max-w-[415px] space-x-6 md:mb-2 px-5 py-3 theme-badge rounded md:rounded-full">
              <span>
                {course.lessonsCount - course.lessonsLeft}/{course.lessonsCount} уроков
              </span>

              <div className="flex-1 flex items-center theme-badge rounded-full overflow-hidden">
                <div className="h-[3px] bg-studentProgressBg" style={{ width: progressBarWidth }} />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex w-full mt-5 md:mt-0 md:mb-2 md:w-auto space-x-2">
            {/* <LMSButton
              className="w-full md:w-auto px-0 md:px-4"
              colorType="light"
              prefix={<CalendarIcon />}
            >
              Расписание <span className="hidden md:inline">занятий</span>
            </LMSButton> */}
            <LMSButton
              className="w-full md:w-auto px-0 md:px-4"
              onClick={() => goToCourseDetail(course.id, course.groupId)}
            >
              Перейти к курсу
            </LMSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
