export default {
  main: 'Басқы',
  profile: 'Профиль',
  content: 'Мазмұны',
  logout: 'Шығу',
  signup: 'Тіркелу',
  signin: 'Кіру',
  loading: 'Жүктелуде...',
  listEmpty: 'Тізім бос',
  allCourses: 'Курс каталогы',
};
