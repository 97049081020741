import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const LandingHeader = () => {
  const { t, i18n } = useTranslation();

  const changeLang = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="max-w-[1200px] w-[90%] h-[86px] flex flex-row justify-between items-center bg-transparent mx-auto py-5 relative mb-4">
      <div className="w-[376px] h-[300px] rounded-[54px] bg-[#3D3D3D] absolute -top-[200px] -left-[98px] z-1 rotate-[18deg]"></div>

      <div className="w-full flex flex-row justify-between items-center gap-5 relative z-10">
        <a href="/" className="max-w-[240px] h-auto">
          <img src={require('assets/images/logo-white.png')} alt="logo" />
        </a>

        <div className="max-w-[700px] w-full flex flex-row justify-between items-center gap-4 relative z-2">
          <a
            href="#about"
            className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px]"
          >
            {t('landing.nav.about')}
          </a>
          <a
            href="#study-programs"
            className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px]"
          >
            {t('landing.nav.programs')}
          </a>
          <a
            href="#appointment"
            className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px]"
          >
            {t('landing.nav.application')}
          </a>
          <div>
            <button
              className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px]"
              onClick={() => changeLang('kk')}
            >
              Қаз
            </button>
            <button
              className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px] ml-2"
              onClick={() => changeLang('ru')}
            >
              Рус
            </button>
          </div>
          <NavLink
            to="/auth/login"
            className="text-lg font-semibold text-black transition hover:text-black hover:-translate-y-[2px]"
          >
            {t('landing.nav.login')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
