import React, { useEffect, useState } from 'react';
import LMSPagination from 'components/LMSPagination';
import { IApplication } from 'modules/hr/entities/Application';
import Loading from 'modules/student/components/Loading';
import { useNotification } from 'hooks/notification';

import { fetchApplications } from '../../api/repository/application';
import ApplicationTable from '../../components/ApplicationTable';
import EditApplicationModal from '../../components/EditApplicationModal';

const CoachList = () => {
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [lastPage, setLastPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedApplication, setSelectedApplication] = useState<IApplication | null>(null);

  const fetchData = async () => {
    setIsLoading(true);
    fetchApplications()
      .then((res) => {
        setApplications(res.data);
        setLastPage(res.lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showApplicationModal = (application: IApplication) => {
    setSelectedApplication(application);
  };

  return (
    <div className="flex flex-col items-start w-full">
      <div className="w-full flex flex-row justify-between lg:items-center mb-6 lg:mb-10">
        <h2 className="text-[32px] font-semibold">Заявки</h2>
      </div>

      {isLoading ? (
        <div className="w-full p-20">
          <Loading />
        </div>
      ) : null}

      {!isLoading && applications && applications.length ? (
        <>
          <div className="w-full overflow-x-auto">
            <ApplicationTable
              applications={applications}
              showApplicationModal={showApplicationModal}
            />
          </div>

          <div className={lastPage > 1 ? 'mt-14' : ''}>
            <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
          </div>
        </>
      ) : null}

      {applications.length === 0 && !isLoading && (
        <h3 className="w-full mb-10 text-[24px] font-semibold text-center mt-10">Нет заявок</h3>
      )}

      {selectedApplication && (
        <EditApplicationModal
          application={selectedApplication}
          onClose={() => setSelectedApplication(null)}
          onCallback={fetchData}
        />
      )}
    </div>
  );
};

export default CoachList;
