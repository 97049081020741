export default {
  nav: {
    links: 'Ссылки',
    home: 'Главная',
    about: 'О нас',
    aboutPlatform: 'О платформе',
    apps: 'Приложения',
    analytics: 'Аналитика',
    interface: 'Интерфейс',
    programs: 'Программы обучения',
    application: 'Записаться на курс',
    login: 'Войти',
    courses: 'Курсы',
    callback: 'Обратный звонок',
    socials: 'Соц.сети',
    contacts: 'Контакты',
    address: 'Республика Казахстан, г.Алматы',
  },
  actions: {
    сooperation: 'Сотрудничество',
    try: 'Попробовать',
    moreReasons: 'Узнать больше о преимуществах',
    allCourses: 'Полный каталог курсов',
  },
  hero: {
    try: 'Попробуйте demo-версию',
    titleTop: 'Комфортное обучение для',
    titleBottom: 'ваших сотрудников',
    subtitle: 'Создавайте обучение и отслеживайте прогресс ваших сотрудников в одной платформе',
    btn: 'Попробовать бесплатно',
  },
  main: {
    title: 'Добро пожаловать в MP Academy!',
    subtitle: 'Мы обучаем профессионалов для работы на современных складских комплексах',
    text1:
      'Это не просто Учебный центр, а стратегический проект, направленный на подготовку специалистов для работы на современных складских комплексах и руководителей новой формации. В условиях растущего дефицита кадров в логистической отрасли мы предоставляем возможности для обучения и профессионального роста, отвечая на потребности рынка. Все программы, которые мы предлагем разработаны нами в сотрудничестве с действущими срециалистами складского хозяйства и опытными руководителями разных подразделений и компании.',
    text2:
      'MP Academy — стратегический проект компании Mercury Properties, которая входит в ТОП-1 среди владельцев коммерческой недвижимости в Казахстане. Наша цель — подготовить специалистов для работы на складах и руководителей новой формации.',
  },
  reasons: {
    title: 'Почему стоит выбрать нас',
    reason1: {
      title: 'Модель обучения 70-20-10',
      text1: '70% - обучение на рабочем месте',
      text2: '20% - наставничество и коучинг от опытных специалистов',
      text3: '10% - теория в аудиториях и онлайн-платформах',
    },
    reason2: {
      title: 'Комплексный подход',
      text: 'Мы готовим специалистов не только в функциональной части, но и развиваем базовые и деловые навыки.',
    },
    reason3: {
      title: 'Гибкость',
      text1: 'Широкий выбор форматов обучения: онлайн, оффлайн, с тренером или самостоятельно.',
      text2: 'Стоимость обучения варьируется в зависимости от выбранного формата.',
    },
  },
  programs: {
    title: 'Программы для специалистов и руководителей',
    subtitle: 'Описание курсов',
    course1: {
      title: 'Курс "Основы складской работы"',
      text1: 'Целевая аудитория: работники и операторы склада.',
      text2: 'Длительность: 4-6 недель.',
      text3: 'Основные задачи:',
      text4: 'Понимание складских процессов.',
      text5: 'Навыки управления собой.',
    },
    course2: {
      title: 'Миссия: Руководитель',
      text1:
        'Подготовка руководителей к эффективному управлению сотрудниками на всех этапах их жизненного цикла.',
      text2:
        'Предназначена для начинающих руководителей разного уровня и руководителей, которые хотели бы расширить и углубить свои знания и умения в области управления персоналом.',
      text3: 'Программа состоит из 34 масстер-классов.',
      text4: 'Обучение онлайн и оффлайн.',
    },
  },
  application: {
    title: 'Задайте нам вопрос',
    subtitle: 'Форма обратной связи',
    callbackText: 'Мы свяжемся с вами в течение 24 часов для уточнения всех деталей.',
    form: {
      name: 'Имя',
      surname: 'Фамилия',
      selectedCourse: 'Выбранный курс',
      submit: 'Отправить заявку',
    },
    messages: {
      success: 'Ваша заявка успешно отправлена',
      error: 'Произошла ошибка. Обратитесь в службу поддержки',
    },
  },
};
