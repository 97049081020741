import React from 'react';
import { useTranslation } from 'react-i18next';

const Reason = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full relative py-14 z-20">
      <div className="max-w-[1200px] w-[90%] flex flex-col items-center gap-8 mx-auto relative z-10">
        <div className="bg-[#F1F6FF] rounded-lg px-9 py-4">
          <h2 className="text-center text-xl font-bold text-[#2878FF] uppercase tracking-widest">
            {t('landing.reasons.title')}
          </h2>
        </div>

        <article className="w-full h-auto bg-white flex flex-col items-center gap-4 shadow-lg p-4 sm:flex-row">
          <div className="w-[260px] min-w-[260px] h-[150px] flex items-center justify-center">
            <img
              src={require('assets/images/landing/model.png')}
              alt="model"
              className="w-auto h-full object-cover object-center"
            />
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-2xl text-black font-bold">{t('landing.reasons.reason1.title')}</p>
            <div className="flex flex-col text-lg text-[#1F1C14B2]">
              <p>{t('landing.reasons.reason1.text1')}</p>
              <p>{t('landing.reasons.reason1.text2')}</p>
              <p>{t('landing.reasons.reason1.text3')}</p>
            </div>
          </div>
        </article>

        <article className="w-full h-auto bg-white flex flex-col items-center gap-4 shadow-lg p-4 sm:flex-row">
          <div className="w-[260px] min-w-[260px] h-[150px] flex items-center justify-center">
            <img
              src={require('assets/images/landing/chain.png')}
              alt="chain"
              className="w-auto h-full object-cover object-center"
            />
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-2xl text-black font-bold">{t('landing.reasons.reason2.title')}</p>
            <div className="flex flex-col text-lg text-[#1F1C14B2]">
              <p>{t('landing.reasons.reason2.text')}</p>
            </div>
          </div>
        </article>

        <article className="w-full h-auto bg-white flex flex-col items-center gap-4 shadow-lg p-4 sm:flex-row">
          <div className="w-[260px] min-w-[260px] h-[150px] flex items-center justify-center">
            <img
              src={require('assets/images/landing/figures.png')}
              alt="figures"
              className="w-auto h-full object-cover object-center"
            />
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-2xl text-black font-bold">{t('landing.reasons.reason3.title')}</p>
            <div className="flex flex-col text-lg text-[#1F1C14B2]">
              <p>{t('landing.reasons.reason3.text1')}</p>
              <p>{t('landing.reasons.reason3.text2')}</p>
            </div>
          </div>
        </article>

        {/* <button className="bg-[#FCA311] rounded-md p-2">
          <p className="text-2xl font-bold text-white">{t('landing.actions.moreReasons')}</p>
        </button> */}
      </div>

      <div className="w-full h-[250px] bg-[#DBE5FF] absolute left-0 right-0 bottom-0 z-0"></div>
    </div>
  );
};

export default Reason;
