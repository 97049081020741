import React from 'react';

import { IApplication } from '../../entities/Application';

interface Props {
  applications: IApplication[];
  showApplicationModal: (application: IApplication) => void;
}

const ApplicationTable: React.FC<Props> = ({ applications, showApplicationModal }) => {
  const showModal = (application: IApplication) => {
    showApplicationModal(application);
  };

  return (
    <table className="bg-coachTableGradient w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px]">
      <thead className="block px-8 pt-4 pb-[10px] w-full bg-darkGray">
        <tr className="grid grid-cols-applicationTable w-full gap-x-8">
          <td>ФИО</td>
          <td>E-MAIL</td>
          <td>КУРС</td>
          <td>КОММЕНТАРИИ</td>
          <td>СТАТУС</td>
        </tr>
      </thead>

      <tbody>
        {applications.length > 0 &&
          applications.map((application) => (
            <tr
              className="grid grid-cols-applicationTable w-full px-8 pt-4 pb-[10px] gap-x-8 border-t-[1px] border-coachTableBorder "
              key={application.id}
            >
              <td className="w-full flex items-center">
                <span
                  className="w-full max-w-full truncate font-medium transition cursor-pointer hover:text-blue"
                  onClick={() => showModal(application)}
                >
                  {application.surname} {application.name}
                </span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{application.email}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{application.course || '-'}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{application.comment || '-'}</span>
              </td>
              <td className="w-full flex items-center">
                <div
                  className={`w-6 h-6 rounded-full ${
                    application.isWorkedOut ? 'bg-green' : 'bg-red-500'
                  }`}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ApplicationTable;
