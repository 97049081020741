import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div
      id="about"
      className="max-w-[1200px] w-[90%] flex flex-col items-center gap-8 mx-auto relative lg:flex-row mb-4"
    >
      <div className="flex flex-col flex-1 pt-8 relative z-10">
        <h1 className="text-4xl text-black font-extrabold leading-[46px] mb-4">
          {t('landing.main.title')}
        </h1>
        <p className="text-2xl text-black font-bold leading-7 mb-4">{t('landing.main.subtitle')}</p>
        <p className="text-lg text-black leading-7 mb-2">{t('landing.main.text1')}</p>
        <p className="text-lg text-black leading-7 mb-2">{t('landing.main.text2')}</p>
      </div>

      <img
        src={require('assets/images/about.png')}
        alt="about"
        className="w-full h-[300px] rounded-t-xl rounded-br-xl rounded-bl-[100px] object-cover overflow-hidden shadow-10xl relative z-10 lg:w-[460px] lg:h-[610px] lg:rounded-bl-[300px]"
      />

      <div className="w-[200px] h-[200px] rounded-3xl bg-[#D6E6FF] rotate-[60deg] absolute top-20 right-1/2 z-1 opacity-45"></div>
    </div>
  );
};

export default About;
