import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

// import translationEn from './en';
import translationKk from './kk';
import translationRu from './ru';

i18n.use(initReactI18next).init({
  resources: {
    kk: { translation: translationKk },
    ru: { translation: translationRu },
    // en: { translation: translationEn },
  },
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
});

export default i18n;
