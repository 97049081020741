import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import LMSButton from 'components/LMSButton';
import { notificationsSlice } from 'store/notifications';
import { useOutsideClick } from 'hooks/outside-click';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { fetchNotifications, setNotificationRead } from '../../api/repository/notifications';
import { Notification } from '../../entities/Notification';

interface LocalNotification extends Notification {
  isOpen: boolean;
}

interface Props {
  leftPosition?: string;
}

const NotificationDropdown: React.FC<Props> = ({ leftPosition = '-right-10' }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unread, ...rest } = useAppSelector((state) => state.notifications);

  const [isOpen, setOpen] = useState(false);
  const [survey, setSurvey] = useState('');

  const [notifications, setNotifications] = useState<LocalNotification[]>([]);

  const notificationRef = useOutsideClick(() => {
    setOpen(false);
  });

  useEffect(() => {
    if (!isOpen) return;

    fetchNotifications(survey ? { surveys_only: survey } : undefined).then((res) => {
      setNotifications(res.map((item) => ({ ...item, isOpen: false })));
    });
  }, [isOpen, survey]);

  const toggleNotification = (notification: LocalNotification) => {
    setNotifications((prev) =>
      prev.map((item) => ({
        ...item,
        isOpen: item.id === notification.id ? !item.isOpen : item.isOpen,
      }))
    );

    if (!notification.isRead) {
      setNotificationRead(notification.id).then(() => {
        setNotifications((prev) =>
          prev.map((item) => ({
            ...item,
            isRead: true,
          }))
        );
        dispatch(
          notificationsSlice.actions.setNotificationsCount({
            unread: unread - 1,
            survey: notification.survey ? rest.survey - 1 : rest.survey,
            total: rest.total,
          })
        );
      });
    }
  };

  const goToSurvey = (notification: LocalNotification) => {
    if (!notification.survey) return;
    navigate(
      `/student/profile/survey/${notification.survey.id}?course_id=${notification.survey.courseId}`
    );
    setOpen(false);
  };

  return (
    <div ref={notificationRef} className="relative">
      <button
        className="flex items-center justify-center w-[42px] lg:w-[54px] h-[42px] lg:h-[54px] border theme-border-icon rounded-full theme-header-button"
        onClick={() => setOpen(true)}
      >
        <div className="theme-default-icon--bell">
          <BellIcon />
        </div>
        {unread ? (
          <span className="absolute top-0 right-0 w-5 h-5 flex items-center justify-center text-xs text-black bg-white rounded-full">
            {unread}
          </span>
        ) : null}
      </button>

      {isOpen ? (
        <div
          className={`absolute top-20 ${leftPosition} md:right-0 w-[340px] md:w-[440px] bg-white rounded-2xl theme-text-notifications z-10`}
        >
          <div className="flex items-center justify-between px-8 py-3 border-b border-black/10">
            <div className="flex items-center space-x-4">
              <button
                className={`${survey === '' ? 'theme-text' : 'theme-text--disabled'}`}
                onClick={() => setSurvey('')}
              >
                Все
              </button>
              <button
                className={`${survey === 'false' ? 'theme-text' : 'theme-text--disabled'}`}
                onClick={() => setSurvey('false')}
              >
                Новости
              </button>
              <button
                className={`${survey === 'true' ? 'theme-text' : 'theme-text--disabled'}`}
                onClick={() => setSurvey('true')}
              >
                Опрос
              </button>
            </div>

            <button className="text-4xl text-active" onClick={() => setOpen(false)}>
              &times;
            </button>
          </div>
          <div className="max-h-[500px] overflow-y-auto">
            {notifications.map((notification) => (
              <div key={notification.id} className="px-8 py-5 border-b border-black/10">
                <div
                  className="flex items-center justify-between space-x-4 cursor-pointer"
                  onClick={() => toggleNotification(notification)}
                >
                  <div className="flex items-center space-x-3 overflow-hidden">
                    <span
                      className={`shrink-0 w-1 h-1 rounded-full ${
                        notification.isRead ? 'bg-grey' : 'bg-blue'
                      }`}
                    />
                    <p className="truncate text-current">{notification.name}</p>
                  </div>
                  <ArrowDownIcon className="shrink-0" />
                </div>
                {notification.isOpen ? (
                  <div className="mt-4">
                    <p className="text-xs mb-2">{notification.description}</p>
                    {notification.survey ? (
                      <LMSButton onClick={() => goToSurvey(notification)}>Пройти опрос</LMSButton>
                    ) : null}
                  </div>
                ) : null}
                <p className="mt-2 text-xs text-black/40">{notification.publishedAt}</p>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationDropdown;
