import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ReactComponent as ChoiseDropdownIcon } from 'assets/icons/choices-role.svg';
import { ReactComponent as CupIcon } from 'assets/icons/cup-blue.svg';
import { ReactComponent as WairlessIcon } from 'assets/icons/hairless.svg';
import { ReactComponent as HRIcon } from 'assets/icons/user-blue.svg';
import { authSlice } from 'store/auth';
import { useOutsideClick } from 'hooks/outside-click';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { sendRole } from 'api/repositories/users.repository';

const ChangeRoleButton = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const dropdownRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  const modifiedRolesName: Record<string, { name: string; icon: React.ReactNode }> = {
    coach: { name: 'Тренер', icon: <CupIcon /> },
    student: { name: 'Слушатель', icon: <WairlessIcon /> },
    hr: { name: 'HR', icon: <HRIcon /> },
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const switchCurrentRole = (role: string) => {
    const id = user.roles.find((r) => r.name === role)?.id;
    sendRole(id || user.roles[0].id).then((res) => {
      dispatch(authSlice.actions.updateUser(res));
    });

    dispatch(authSlice.actions.userFetchingSuccess({ ...user, currentRole: role }));
  };

  return (
    <div className="flex flex-col">
      {user?.roles.length > 1 ? (
        <div ref={dropdownRef} className="relative w-fit flex flex-col z-10">
          <button
            type="button"
            onClick={() => toggleDropdown()}
            className="w-fit flex flex-row items-center gap-x-4 p-2 text-current text-[16px] rounded-full theme-header-button lg:pr-5"
          >
            <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white theme-header-icon theme-border-icon">
              <ChoiseDropdownIcon />
            </div>
            <span className="hidden lg:block">Cменить кабинет</span>
          </button>
          {isOpen && (
            <div className="min-w-[200px] z-10 absolute right-0 top-16 bg-white min-h-[110px] h-max rounded-[16px] md:left-0 lg:w-full">
              {user.roles.map((role, idx) => {
                const translatedRole = modifiedRolesName[role.display_name] || {
                  name: role.display_name,
                  icon: null,
                };
                return (
                  <div
                    key={idx}
                    onClick={() => switchCurrentRole(role.name)}
                    className="cursor-pointer border-b last-of-type:border-none"
                  >
                    <div
                      className={`${
                        user.currentRoleId === role.id ? 'theme-active-effect rounded-[16px]' : ''
                      } flex flex-row items-center gap-x-3 p-4 text-black theme-active-effect--hover hover:rounded-[16px]`}
                    >
                      {translatedRole.icon} {translatedRole.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ChangeRoleButton;
