import request from 'api/axios-instance';

import {
  IQuestionAnswer,
  IQuestionAudio,
  ISimulation,
  ISimulationDetail,
  ISimulationQuestion,
} from '../../entities/Simulation';
import {
  IQuestionAnswerDao,
  IQuestionAudioDao,
  ISimulationDao,
  ISimulationDetailDao,
  ISimulationQuestionDao,
} from '../dao/Simulation.dao';

export const fetchSimulations = (page?: number, perPage?: number) =>
  request.get('v2/game/lectio/simulations', { params: { page, perPage } }).then((res) => {
    const simulations: ISimulation[] = res.data.data.map(simulationDaoToEntity);
    return { simulations, lastPage: res?.data?.meta?.last_page || 1 };
  });

export const fetchSimulation = (id: number) =>
  request.get(`v2/game/lectio/simulations/${id}`).then((res) => {
    const simulation: ISimulationDetail = simulationDetailDaoToEntity(res.data.data);
    return simulation;
  });

export const createSimulation = (data: Record<string, string | number>) =>
  request.post(`v2/game/lectio/simulations`, data).then((res) => res.data);

export const updateSimulation = (id: number, data: Record<string, string | number>) =>
  request.post(`v2/game/lectio/simulations/${id}`, data).then((res) => res.data);

export const deleteSimulation = (id: number) =>
  request.delete(`v2/game/lectio/simulations/${id}`).then((res) => res.data);

export const postSimulationQuestions = (id: number, data: ISimulationQuestion[]) => {
  const questionEntity = data.map(questionEntityToDao);
  return request.post(`v2/game/lectio/simulations/${id}/questions`, {
    questions: questionEntity,
  });
};

function simulationDaoToEntity(simulationDao: ISimulationDao): ISimulation {
  return {
    id: simulationDao.id,
    target: simulationDao.target,
    description: simulationDao.description,
    instruction: simulationDao.instruction,
    categoryDescription: simulationDao.category_description,
    questions: simulationDao.questions,
    timerDescription: simulationDao.timer_description,
    tryDescription: simulationDao.try_description,
    resultsDescription: simulationDao.results_description,
    categoryId: simulationDao.category_id,
    categoryName: simulationDao.category_name,
    seconds: simulationDao.seconds,
  };
}

function simulationDetailDaoToEntity(simulationDao: ISimulationDetailDao): ISimulationDetail {
  return {
    id: simulationDao.id,
    target: simulationDao.target,
    description: simulationDao.description,
    instruction: simulationDao.instruction,
    categoryDescription: simulationDao.category_description,
    questions: simulationDao.questions,
    timerDescription: simulationDao.timer_description,
    tryDescription: simulationDao.try_description,
    resultsDescription: simulationDao.results_description,
    categoryId: simulationDao.category_id,
    categoryName: simulationDao.category_name,
    seconds: simulationDao.seconds,
    lectioQuestions: simulationDao.lectioQuestions.map(questionDaoToEntity),
  };
}

function questionDaoToEntity(questionDao: ISimulationQuestionDao): ISimulationQuestion {
  return {
    id: questionDao.id,
    title: questionDao.title,
    translation: questionDao.translation,
    typeId: questionDao.type_id,
    typeName: questionDao.type_name,
    answers: Array.isArray(questionDao.answers)
      ? questionDao.answers.map(answerDaoToEntity)
      : answerDaoToEntity(questionDao.answers),
    seconds: questionDao.seconds,
    audio: questionDao.audio
      ? Array.isArray(questionDao.audio)
        ? questionDao.audio.map(audioDaoToEntity)
        : questionDao.audio
      : null,
    newAudio: questionDao.new_audio,
    position: questionDao.position,
  };
}

function answerDaoToEntity(answerDao: IQuestionAnswerDao): IQuestionAnswer {
  return {
    name: answerDao.name,
    isCorrect: answerDao.is_correct,
  };
}

function questionEntityToDao(question: ISimulationQuestion): ISimulationQuestionDao {
  return {
    id: question.id,
    title: question.title,
    translation: question.translation,
    type_id: question.typeId,
    type_name: question.typeName,
    answers: Array.isArray(question.answers)
      ? question.answers.map(answerEntityToDao)
      : answerEntityToDao(question.answers),
    seconds: question.seconds,
    audio: question.audio
      ? Array.isArray(question.audio)
        ? question.audio.map(audioEntityToDao)
        : question.audio
      : null,
    new_audio: question.newAudio,
    position: question.position,
  };
}

function answerEntityToDao(answer: IQuestionAnswer): IQuestionAnswerDao {
  return {
    name: answer.name,
    is_correct: answer.isCorrect,
  };
}

function audioDaoToEntity(audioDao: IQuestionAudioDao): IQuestionAudio {
  return {
    downloadLink: audioDao.download_link,
    hash: audioDao.hash,
    name: audioDao.name,
    size: audioDao.size,
  };
}

function audioEntityToDao(audio: IQuestionAudio): IQuestionAudioDao {
  return {
    download_link: audio.downloadLink,
    hash: audio.hash,
    name: audio.name,
    size: audio.size,
  };
}
