import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/emoticons/dislike.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/emoticons/heart.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/emoticons/like.svg';
import { ReactComponent as LoveIcon } from 'assets/icons/emoticons/love.svg';
import { ReactComponent as OmgIcon } from 'assets/icons/emoticons/omg.svg';
import { ReactComponent as SadIcon } from 'assets/icons/emoticons/sad.svg';
import { ReactComponent as SmileIcon } from 'assets/icons/emoticons/smile.svg';
import { ReactComponent as MicrophoneIcon } from 'assets/icons/microphone.svg';
import BasePopup from 'components/BasePopup';
import LMSButton from 'components/LMSButton';
import TimerProgressBar from 'components/TimerProgressBar';
import { ISimulationQuestion } from 'modules/coach/entities/Simulation';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import Timer from '../Timer';

interface Props {
  questions: ISimulationQuestion[];
  timer?: number;
  nextStep: () => void;
  setCountCorrectAnswersForResult: (count: number) => void;
}

const LectioTest = ({
  questions,
  timer = 120,
  nextStep,
  setCountCorrectAnswersForResult,
}: Props) => {
  const notify = useNotification();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answer, setAnswer] = useState<string>('');
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [showQuestion, setShowQuestion] = useState<boolean>(true);
  const [isAnswerChecked, setIsAnswerChecked] = useState<boolean>(false);
  const [isResultMessageCorrect, setIsResultMessageCorrect] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const START_TIME: number = Date.now();

  const sendGameStats = () => {
    const END_TIME: number = Date.now();
    const time = ((END_TIME - START_TIME) / 1000).toFixed(2);
    const result: TData = {
      game: 'lectio',
      difficulty: 'medium',
      score: countCorrectAnswers,
      time: Math.ceil(Number(time)),
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const nextQuestion = () => {
    stopAudio();
    const nextQuestionIndex = currentQuestionIndex + 1;
    if (questions.length <= nextQuestionIndex) {
      setCountCorrectAnswersForResult(countCorrectAnswers);
      sendGameStats();
    } else {
      setAnswer('');
      setShowQuestion(true);
      setIsAnswerChecked(false);
      setIsResultMessageCorrect(false);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const checkAnswer = () => {
    stopAudio();
    const currentQuestion = questions[currentQuestionIndex];
    let isCorrect;
    if (Array.isArray(currentQuestion.answers)) {
      const foundAnswer = currentQuestion.answers.find((value) => value.isCorrect);
      isCorrect = foundAnswer
        ? foundAnswer.name.trim().toLowerCase() === answer.trim().toLowerCase()
        : false;
    } else {
      isCorrect = currentQuestion.answers.name.trim().toLowerCase() === answer.trim().toLowerCase();
    }
    setIsAnswerChecked(true);

    if (isCorrect) {
      setCountCorrectAnswers(countCorrectAnswers + 1);
      setIsResultMessageCorrect(true);
    } else {
      setIsResultMessageCorrect(false);
    }
  };

  const handleFinishTimer = () => {
    stopAudio();
    notify.error('Время вышло! Вы переходите к следующему вопросу.');
    setTimeout(() => checkAnswer());
  };

  const handleFinishTimerProgress = () => {
    stopAudio();
    setShowQuestion(false);
  };

  const getQuestionCondition = (questionTypeId: number): string => {
    switch (questionTypeId) {
      case 2:
        return 'Выберите слово, которое было показано на экране';
      case 3:
        return 'Напишите слово, которое было показано на экране';
      default:
        return 'Внимательно посмотрите на слово и напишите его правильно';
    }
  };

  const getRandomNumber = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomEmoticon = (isGoodEmoticon: boolean) => {
    const args = isGoodEmoticon ? { min: 1, max: 4 } : { min: 5, max: 7 };
    const iconNumber = getRandomNumber(args.min, args.max);

    switch (iconNumber) {
      case 1:
        return <LikeIcon />;
      case 2:
        return <HeartIcon />;
      case 3:
        return <LoveIcon />;
      case 4:
        return <SmileIcon />;
      case 5:
        return <DislikeIcon />;
      case 6:
        return <OmgIcon />;
      case 7:
        return <SadIcon />;
    }
  };

  const playAudio = () => {
    stopAudio();
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    const currentQuestion = questions[currentQuestionIndex];
    if (currentQuestion.audio && Array.isArray(currentQuestion.audio)) {
      audioRef.current = new Audio(currentQuestion.audio[0].downloadLink);
    }

    return () => {
      stopAudio();
    };
  }, [currentQuestionIndex]);

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 py-20 m-auto">
      {currentQuestion ? (
        <div className="flex flex-col space-y-8">
          {isAnswerChecked ? (
            <div className="flex flex-col items-center justify-center pt-10">
              <p className="text-[24px] font-semibold text-center leading-8 mb-10">
                {getRandomEmoticon(isResultMessageCorrect)}
              </p>
              <p className="text-[24px] font-semibold text-center leading-8 mb-5">
                {isResultMessageCorrect ? 'Отлично! Ваш ответ правильный!' : 'Упс, неверно!'}
              </p>
              <p className="text-[18px] text-center leading-6 mb-16">
                {isResultMessageCorrect ? 'Продолжайте в том же духе' : 'Попробуйте ещё раз.'}
              </p>
              <LMSButton className="self-center" suffix={<ArrowRight />} onClick={nextQuestion}>
                Далее
              </LMSButton>
            </div>
          ) : (
            <>
              <div className="w-full flex flex-row justify-between items-center">
                <p className="font-base opacity-50">Задание №{currentQuestionIndex + 1}</p>
                {showQuestion &&
                (currentQuestion.typeId === 2 || currentQuestion.typeId === 3) ? null : (
                  <Timer time={timer} className="!text-[16px]" onClose={handleFinishTimer} />
                )}
              </div>

              <div className="flex flex-col max-w-[460px] min-w-[340px] w-full mx-auto">
                {currentQuestion.typeId === 1 ? (
                  <div className="flex flex-col items-center justify-center mb-16">
                    <p className="text-[24px] font-semibold text-center leading-8 mb-5">
                      {getQuestionCondition(currentQuestion.typeId)}
                    </p>
                    <div className="flex flex-row items-center justify-center mb-5">
                      <p className="text-[60px] font-bold text-center">{currentQuestion.title}</p>
                      {currentQuestion.translation && (
                        <button
                          className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4 relative"
                          onClick={() => setIsOpenPopup(true)}
                        >
                          <img
                            src={require('assets/images/trans.png')}
                            alt="trans"
                            className="w-full h-full object-cover object-center"
                          />
                          {isOpenPopup && (
                            <BasePopup isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)}>
                              <aside className="text-black w-max h-max gap-y-1">
                                <p className="text-black text-lg">{currentQuestion.translation}</p>
                              </aside>
                            </BasePopup>
                          )}
                        </button>
                      )}
                      {currentQuestion.audio && Array.isArray(currentQuestion.audio) && (
                        <button
                          className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4"
                          onClick={playAudio}
                        >
                          <MicrophoneIcon />
                        </button>
                      )}
                    </div>
                    <input
                      className="max-h-[52px] w-full max-w-[400px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
                      value={answer}
                      placeholder="Введите название"
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                  </div>
                ) : currentQuestion.typeId === 2 && Array.isArray(currentQuestion.answers) ? (
                  <div className="flex flex-col items-center justify-center mb-14">
                    {showQuestion ? (
                      <div>
                        <p className="text-[18px] text-center leading-6 mb-5">
                          Запомните написание слова, которое видите, и выберите правильный ответ
                          после окончания времени.
                        </p>
                        <div className="flex flex-row items-center justify-center mb-5">
                          <p className="text-[60px] font-bold text-center">
                            {currentQuestion.title}
                          </p>
                          {currentQuestion.translation && (
                            <button
                              className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4 relative"
                              onClick={() => setIsOpenPopup(true)}
                            >
                              <img
                                src={require('assets/images/trans.png')}
                                alt="trans"
                                className="w-full h-full object-cover object-center"
                              />
                              {isOpenPopup && (
                                <BasePopup
                                  isOpen={isOpenPopup}
                                  onClose={() => setIsOpenPopup(false)}
                                >
                                  <aside className="text-black w-max h-max gap-y-1">
                                    <p className="text-black text-lg">
                                      {currentQuestion.translation}
                                    </p>
                                  </aside>
                                </BasePopup>
                              )}
                            </button>
                          )}
                          {currentQuestion.audio && (
                            <button
                              className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4"
                              onClick={playAudio}
                            >
                              <MicrophoneIcon />
                            </button>
                          )}
                        </div>
                        <TimerProgressBar
                          duration={currentQuestion.seconds || 30}
                          onCallback={handleFinishTimerProgress}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-[24px] font-semibold text-center leading-8 mb-7">
                          {getQuestionCondition(2)}
                        </p>
                        <div className="w-full max-w-[400px] flex flex-col space-y-4">
                          {currentQuestion.answers.map((questionAnswer, idx) => (
                            <button
                              key={`answer-${idx}`}
                              type="button"
                              className={`h-[55px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6] border-2 border-transparent rounded-[14px] outline-none text-black text-xl ${
                                questionAnswer.name === answer && '!border-green'
                              }`}
                              onClick={() => setAnswer(questionAnswer.name)}
                            >
                              {questionAnswer.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mb-16">
                    {showQuestion ? (
                      <>
                        <p className="text-[18px] text-center leading-6 mb-5">
                          Запомните написание слова, которое видите, и введите правильный ответ
                          после окончания времени.
                        </p>
                        <div className="flex flex-row items-center justify-center mb-5">
                          <p className="text-[60px] font-bold text-center">
                            {currentQuestion.title}
                          </p>
                          {currentQuestion.translation && (
                            <button
                              className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4 relative"
                              onClick={() => setIsOpenPopup(true)}
                            >
                              <img
                                src={require('assets/images/trans.png')}
                                alt="trans"
                                className="w-full h-full object-cover object-center"
                              />
                              {isOpenPopup && (
                                <BasePopup
                                  isOpen={isOpenPopup}
                                  onClose={() => setIsOpenPopup(false)}
                                >
                                  <aside className="text-black w-max h-max gap-y-1">
                                    <p className="text-black text-lg">
                                      {currentQuestion.translation}
                                    </p>
                                  </aside>
                                </BasePopup>
                              )}
                            </button>
                          )}
                          {currentQuestion.audio && (
                            <button
                              className="h-11 w-11 theme-default-button--active flex items-center justify-center rounded-lg ml-4"
                              onClick={playAudio}
                            >
                              <MicrophoneIcon />
                            </button>
                          )}
                        </div>
                        <TimerProgressBar
                          duration={currentQuestion.seconds || 30}
                          onCallback={handleFinishTimerProgress}
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-[24px] font-semibold text-center leading-8 mb-7">
                          {getQuestionCondition(3)}
                        </p>
                        <input
                          className="max-h-[52px] w-full max-w-[400px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
                          value={answer}
                          placeholder="Введите название"
                          onChange={(e) => setAnswer(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                )}

                <LMSButton
                  className="self-center"
                  disabled={!answer}
                  suffix={<ArrowRight />}
                  onClick={checkAnswer}
                >
                  Далее
                </LMSButton>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LectioTest;
