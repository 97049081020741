import React, { useState } from 'react';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { IApplication } from 'modules/hr/entities/Application';
import { useNotification } from 'hooks/notification';

import { postApplication } from '../../api/repository/application';

interface Props {
  application: IApplication;
  onClose: () => void;
  onCallback: () => void;
}

const EditApplicationModal: React.FC<Props> = ({ application, onClose, onCallback }) => {
  const notify = useNotification();

  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const getTitle = (data: IApplication) => {
    let title = `Заявка №${data.id}`;
    if (data.course) title += `, ${data.course}`;
    return title;
  };

  const updateApplication = (status: boolean) => {
    setLoading(true);
    postApplication(application.id, { status, comment })
      .then(() => {
        notify.success('Заявка обновлена');
        onClose();
        onCallback();
      })
      .catch(() => {
        notify.success('Неизвестная ошибка! Попробуйте позже');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BaseModal wrapperClassName="sm:!max-w-[600px]" onClose={onClose}>
      <div className="flex flex-col space-y-6 min-w-full lg:min-w-[553px] max-h-[80vh] overflow-y-auto">
        <LMSTitle tag="h2">{getTitle(application)}</LMSTitle>

        <div className="flex flex-col gap-y-3">
          <span className="text-base">Комментарий</span>
          <div className="w-full h-full">
            <textarea
              value={comment}
              onChange={(data) => setComment(String(data.target.value))}
              placeholder="Напишите причину отказа или одобрения"
              className="w-full min-h-[150px] max-h-[300px] p-4 border border-[#26A7EF] rounded outline-none"
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 lg:flex-row">
          <LMSButton
            variant="solid"
            colorType="main"
            className="w-full"
            loading={loading}
            onClick={() => updateApplication(true)}
          >
            Одобрить
          </LMSButton>
          <LMSButton
            variant="solid"
            isRed
            colorType="main"
            className="w-full"
            loading={loading}
            onClick={() => updateApplication(false)}
          >
            Отклонить
          </LMSButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditApplicationModal;
